
import { Options, Vue } from "vue-class-component";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import InputText from "primevue/inputtext";
import Calendar from "primevue/calendar";
import Checkbox from "primevue/checkbox";
import Dropdown from "primevue/dropdown";
import Message from "primevue/message";
import Dialog from "primevue/dialog";
import Field from "@/components/Field.vue";
import EmdAddressService from "@/service/emdAddress";
import AtaTmpPat from "@/model/ataTmpPat";
import AtaTmpPatUtil from "@/util/ataTmpPatUtil";
import DnaValidation from "@/model/dnaValidation";
import Ethnicity from "@/model/enums/ethnicity";
import Gender from "@/model/enums/gender";
import Abo from "@/model/enums/abo";
import Rh from "@/model/enums/rh";
import DgEmdis from "@/model/enums/dgemdis";
import SearchScope from "@/model/enums/searchScope";
import Dispha from "@/model/enums/dispha";
import Cmv from "@/model/enums/cmv";
import Status from "@/model/enums/status";
import EmdAddress from "@/model/emdAddress";
import PatientMode from "@/util/enums/patientMode";
import Alert from "@/util/alert";
import { getConfig } from "@/config";
import { namespace } from "vuex-class";
import Config from "@/model/config";

const ataTmpPat = namespace("ataTmpPat");

@Options({
  components: {
    Field,
    TabView,
    TabPanel,
    InputText,
    Calendar,
    Dropdown,
    Message,
    Dialog,
    Checkbox,
  },
  // watch: { // Mostly only for reference purpose
  //   "selected.personalnumber": {
  //     handler: "valid",
  //   },
  // },
})
export default class EditPatient extends Vue {
  @ataTmpPat.State
  selected!: AtaTmpPat;
  @ataTmpPat.State
  validationMap!: Map<string, string[]>;
  @ataTmpPat.State
  alerts!: Alert[];
  @ataTmpPat.State
  mode!: PatientMode;

  Gender = Gender;
  Ethnicity = Ethnicity;
  Abo = Abo;
  Rh = Rh;
  DgEmdis = DgEmdis;
  SearchScope = SearchScope;
  Dispha = Dispha;
  Cmv = Cmv;
  Status = Status;
  dnaLaboratory = [] as EmdAddress[];
  PatientMode = PatientMode;

  idclass = "p-col-6 p-mb-2";
  medicalclass = "p-col-3 p-mb-2";
  dnaclass = "p-col-2 p-mb-2 p-mr-2";
  idWidth = "150px";
  medicalWidth = "250px";
  dnaWidth = "100px";
  dnaBottomWidth = "200px";

  personalNumberMode?: "se" | "fi" | "est";
  config?: Config;

  async created() {
    this.config = await getConfig();
    this.valid();
    await EmdAddressService.dnaLaboratory().then(
      (resp) => (this.dnaLaboratory = resp.data)
    );
  }

  getProps(field: string, css: string) {
    let disabled = field === "personalnumber" ? !this.disable : this.disable;
    disabled = field === "patientid1" ? true : disabled;
    return {
      disabled,
      class: this.getclass(field, css),
      id: field,
    };
  }

  getFixedProps(width: string) {
    return {
      class: "p-col-fixed",
      style: "width:" + width,
    };
  }

  getTooltip(field: string) {
    const o = this.validationMap.get(field);
    return o ? o[0] : { disabled: true };
  }

  getclass(val: string, def: string) {
    return [def, { "p-invalid": this.validationMap.get(val) }];
  }

  get disable() {
    return (
      this.mode === PatientMode.NONE ||
      this.mode === PatientMode.OTHER_TC ||
      this.mode === PatientMode.DUPLICATE ||
      this.mode === PatientMode.EDIT_CONFIRM
    );
    // return false;
  }

  get yearRange() {
    return `${new Date().getFullYear() - 150}:${new Date().getFullYear()}`;
  }

  closingEditPatDialog(edit: boolean) {
    if (edit) this.setMode(PatientMode.EDIT);
    else this.setMode(PatientMode.NONE);
    this.valid();
  }

  @ataTmpPat.Mutation("SET_MODE")
  setMode!: (mode: PatientMode) => void;

  @ataTmpPat.Mutation("ADD_ERROR")
  addError!: ({ field, msg }: { field: string; msg: string }) => void;

  @ataTmpPat.Mutation("RESET_ERROR")
  resetValidation!: () => void;

  async confirm() {
    await this.patientExists(this.selected.personalnumber);
    if (this.mode == PatientMode.NEW) this.parsePersonalnumber();
    this.valid();
  }

  /**
   * remove whitespace char, uppercase
   * 1XX | 1:ds -> 01XX | 01:DS
   *  not used 1X | 1XXX -> 1XX
   * 1 -> 01XX
   * 12 -> 12XX
   * 1245-> 12:45
   * @param dna 
   */
  performDna(dna: string) {
    //@ts-ignore
    let ve = this.selected[dna]
    const regex = /\s+/gi
    ve = ve.replaceAll(regex, "").toUpperCase();
    //        ve = ve.replaceAll(":X+^","XX");
    if (ve.length > 1 && (ve.charAt(1) == ':' || ve.charAt(1) == 'X'))
      ve = "0" + ve;
    if (ve.length == 2)
      ve = ve + "XX";
    if (ve.length == 1)
      ve = "0" + ve + "XX";
    if (ve.length == 3 && ve.charAt(2) == ':')
      ve = ve + "XX";
    if (ve.length >= 3 && ve.charAt(2) != ':')
      ve = ve.substring(0, 2) + ':' + ve.substring(2);
    //@ts-ignore
    this.selected[dna] = ve
  }


  async valid() {
    this.resetValidation();
    if (this.disable) {
      if (!this.validPersonalnumber())
        this.addError({ field: "personalnumber", msg: "Invalid" });
      return;
    }

    if (!this.selected.consent) {
      this.addError({
        field: "consent",
        msg: "Unable to register a patient without consent to share personal data",
      });
    }

    // Id tab
    if (!this.selected.lastname || this.selected.lastname.length == 0)
      this.addError({ field: "lastname", msg: "Required" });
    else if (this.selected.lastname.length > 30)
      this.addError({ field: "lastname", msg: "Max 30 characters" });

    if (!this.selected.firstname || this.selected.firstname.length == 0)
      this.addError({ field: "firstname", msg: "Required" });
    else if (this.selected.firstname.length > 30)
      this.addError({ field: "firstname", msg: "Max 30 characters" });

    if (!this.birthdate) this.addError({ field: "birthdate", msg: "Required" });
    else if (!(this.birthdate instanceof Date))
      this.addError({ field: "birthdate", msg: "Not a date" });

    if (
      (!this.selected.gender && this.selected.gender != 0) ||
      this.selected.gender == 2
    )
      this.addError({ field: "gender", msg: "Required" });

    if (this.selected.weight != null && isNaN(+this.selected.weight))
      this.addError({ field: "weight", msg: "Must be number" });

    if (this.selected.height != null && isNaN(+this.selected.height))
      this.addError({ field: "height", msg: "Must be number" });

    // Medical tab
    if (!this.selected.dgemdis || this.selected.dgemdis == 0)
      this.addError({ field: "dgemdis", msg: "Required" });

    if (this.dateofdg && !(this.dateofdg instanceof Date))
      this.addError({ field: "dateofdg", msg: "Not a date" });
    if (this.statusdate && !(this.statusdate instanceof Date))
      this.addError({ field: "statusdate", msg: "Not a date" });

    if (!this.selected.searchscope || this.selected.searchscope == 0)
      this.addError({ field: "searchscope", msg: "Required" });

    // DNA tab
    if (this.datetestdna && !(this.datetestdna instanceof Date))
      this.addError({ field: "datetestdna", msg: "Not a date" });

    await this.validateDna();
  }

  validPersonalnumber() {
    const pn = this.selected.personalnumber;
    this.personalNumberMode = undefined;
    if (this.config?.country == "se") {
      if (this.validSePersonalNumber(pn)) this.personalNumberMode = "se";
    } else {
      if (this.validFiPersonalNumber(pn)) this.personalNumberMode = "fi";
      else if (this.validEstPersonalNumber(pn)) this.personalNumberMode = "est";
    }
    return !!this.personalNumberMode;
  }

  validFiPersonalNumber(pn: string) {
    if (!/^\d{6}[-A+]{1}\d{3}[\w\d]{1}$/g.test(pn)) return false; //TODO better regex (1 < month > 12, 1 < day < 31)

    let century = "";
    switch (pn.charAt(6)) {
      case "+":
        century = "18";
        break;
      case "-":
        century = "19";
        break;
      case "A":
        century = "20";
        break;
    }

    if (
      !this.dateIsValid(
        +(century + pn.slice(4, 6)),
        +pn.slice(2, 4),
        +pn.slice(0, 2)
      )
    )
      return false;

    const s = "0123456789ABCDEFHJKLMNPRSTUVWXY";
    // if (!s.includes(pn.charAt(10))) return false;
    const c = pn.slice(0, 6) + pn.slice(7, 10);
    // if (isNaN(+c)) return false;
    if (+c % 31 >= s.length) return false;
    return s[+c % 31] == pn.charAt(10);
  }

  validEstPersonalNumber(pn: string) {
    if (!/^\d{11}$/g.test(pn)) return false;

    let s =
      +pn.charAt(0) +
      +pn.charAt(1) * 2 +
      +pn.charAt(2) * 3 +
      +pn.charAt(3) * 4 +
      +pn.charAt(4) * 5 +
      +pn.charAt(5) * 6 +
      +pn.charAt(6) * 7 +
      +pn.charAt(7) * 8 +
      +pn.charAt(8) * 9 +
      +pn.charAt(9);
    return (s % 11) + "" == pn.charAt(10);
  }

  validSePersonalNumber(pn: string) {
    // YYMMDD-xxxc
    // YYYYMMDD-xxxc
    //if (!/^(\d{6}|\d{8})[-+]\d{4}$/g.test(pn)) return false; //TODO better regex (1 < month > 12, 1 < day < 31)
    if (!/^\d{6}[-+]\d{4}$/g.test(pn)) return false;

    const cy = new Date().getUTCFullYear() + "";
    let y = cy.slice(0, 2);
    if (pn.length > 11) {
      // If YYYY format. Not possible at the moment
      y = pn.slice(0, 2);
      pn = pn.slice(2);
    } else {
      if (+(y + pn.slice(0, 2)) > +cy || pn.charAt(6) == "+") y = +y - 1 + "";
    }
    if (
      !this.dateIsValid(+(y + pn.slice(0, 2)), +pn.slice(2, 4), +pn.slice(4, 6))
    )
      return false;

    if (pn.length > 11) pn = pn.slice(2);
    const add = (n: number) =>
      (n + "").split("").reduce((p, c) => (p += +c), 0);
    let sum = 0;
    sum += add(+pn.charAt(0) * 2);
    sum += add(+pn.charAt(1) * 1);
    sum += add(+pn.charAt(2) * 2);
    sum += add(+pn.charAt(3) * 1);
    sum += add(+pn.charAt(4) * 2);
    sum += add(+pn.charAt(5) * 1);
    sum += add(+pn.charAt(7) * 2);
    sum += add(+pn.charAt(8) * 1);
    sum += add(+pn.charAt(9) * 2);

    const checksum = (10 - (sum % 10)) % 10;
    return checksum + "" == pn.charAt(10);
  }

  parsePersonalnumber() {
    const pn = this.selected.personalnumber;
    if (this.personalNumberMode == "se") this.parseSePersonalNumber(pn);
    else if (this.personalNumberMode == "fi") this.parseFiPersonalNumber(pn);
    else if (this.personalNumberMode == "est") this.parseEstPersonalNumber(pn);
    return !!this.personalNumberMode;
  }

  parseFiPersonalNumber(pn: string) {
    this.selected.gender = ((+pn.slice(8, 10) % 2) + 1) % 2; //Odd => 0 (Male), Even => 1 (Female)

    let century = "";
    switch (pn.charAt(6)) {
      case "+":
        century = "18";
        break;
      case "-":
        century = "19";
        break;
      case "A":
        century = "20";
        break;
    }
    this.birthdate = new Date(
      +(century + pn.slice(4, 6)),
      +pn.slice(2, 4) - 1,
      +pn.slice(0, 2)
    );
  }

  parseEstPersonalNumber(pn: string) {
    // 36411040303
    // 3 64 11 04 030 3
    this.selected.gender = ((+pn.slice(0, 1) % 2) + 1) % 2; //Odd => 0 (Male), Even => 1 (Female)

    this.birthdate = new Date(
      Math.round(+pn.slice(0, 1) * 0.5) * 100 + 1700 + +pn.slice(1, 3),
      +pn.slice(3, 5) - 1,
      +pn.slice(5, 7)
    );
  }

  parseSePersonalNumber(pn: string) {
    // YYMMDD-xxxc
    // YYYYMMDD-xxxc

    /*
    210302-9407 -> 1921
    210703-4346 -> 2021
    */
    const cy = new Date().getUTCFullYear() + "";
    let y = cy.slice(0, 2);
    if (pn.length > 11) {
      y = pn.slice(0, 2);
      pn = pn.slice(2);
    } else {
      if (+(y + pn.slice(0, 2)) > +cy) y = +y - 1 + "";
      if (pn.charAt(6) == "+") y = +y - 1 + "";
    }
    this.selected.gender = ((+pn.charAt(9) % 2) + 1) % 2; //Odd => 0 (Male), Even => 1 (Female)
    // console.log(+(y + pn.slice(0, 2)));
    this.birthdate = new Date(
      +(y + pn.slice(0, 2)),
      +pn.slice(2, 4) - 1,
      +pn.slice(4, 6)
    );
  }

  async validateDna() {
    const dna: DnaValidation = {
      a1: this.selected.a1,
      a2: this.selected.a2,
      b1: this.selected.b1,
      b2: this.selected.b2,
      c1: this.selected.c1,
      c2: this.selected.c2,
      drb11: this.selected.drb11,
      drb12: this.selected.drb12,
      drb31: this.selected.drb31,
      drb32: this.selected.drb32,
      drb41: this.selected.drb41,
      drb42: this.selected.drb42,
      drb51: this.selected.drb51,
      drb52: this.selected.drb52,
      dqb11: this.selected.dqb11,
      dqb12: this.selected.dqb12,
      dqa11: this.selected.dqa11,
      dqa12: this.selected.dqa12,
      dpb11: this.selected.dpb11,
      dpb12: this.selected.dpb12,
      dpa11: this.selected.dpa11,
      dpa12: this.selected.dpa12,
    };
    const errors = this.validationMap.size;
    let empty = true;
    Object.entries(dna).forEach(([key, value]) => {
      if (value) empty = false;
      if (!this.validDna(value))
        this.addError({
          field: key,
          msg: "Invalid Dna",
        });
    });
    if (!empty && errors == this.validationMap.size) {
      await this.dnaValidation(dna);
    }
  }

  validDna(dna: string): boolean {
    if (!dna || dna.length == 0) return true;
    if (!/^([\dA-Z]{2,5}:){0,3}[\dA-Z]{2,5}$/g.test(dna)) return false;
    return true;
  }

  utcDate(date: Date | undefined): Date | undefined {
    if (date instanceof Date) {
      date = new Date(
        Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
      );
    }
    return date;
  }

  dateIsValid(y: number, m: number, d: number) {
    const dd = new Date(y, m - 1, d);
    return (
      `${dd.getFullYear()}-${dd.getMonth() + 1}-${dd.getDate()}` ==
      `${y}-${m}-${d}`
    );
  }

  get birthdate() {
    return AtaTmpPatUtil.parseDate(this.selected.birthdate);
  }

  set birthdate(value: Date | undefined) {
    this.selected.birthdate = this.utcDate(value);
  }

  get dateofdg() {
    return AtaTmpPatUtil.parseDate(this.selected.dateofdg);
  }

  set dateofdg(value) {
    this.selected.dateofdg = this.utcDate(value);
  }

  get statusdate() {
    return AtaTmpPatUtil.parseDate(this.selected.statusdate);
  }

  set statusdate(value) {
    this.selected.statusdate = this.utcDate(value);
  }

  get datetestdna() {
    return AtaTmpPatUtil.parseDate(this.selected.datetestdna);
  }

  set datetestdna(value) {
    this.selected.datetestdna = this.utcDate(value);
  }

  get idNameLabel(): string {
    let o = this.selected.patientid ? this.selected.patientid : "";
    if (this.selected.firstname || this.selected.lastname) {
      o += `${o ? " -" : ""} ${this.selected.lastname} ${this.selected.firstname
        }`;
    }
    return o;
  }

  @ataTmpPat.Action
  patientExists!: (personalnumber: string) => void;

  @ataTmpPat.Action
  dnaValidation!: (dna: DnaValidation) => void;

  @ataTmpPat.Action
  save!: () => void;
}
