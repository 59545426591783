import Enum from "@/util/enum";
import EnumValue from ".";

export default class DgEmdis extends Enum {
  // static UNKNOWN: EnumValue = { name: "Unknown", code: 0 };
  static AML: EnumValue = { name: "AML Acute Myelogenous Leukaemia", code: 1 };
  static ALL: EnumValue = {
    name: "ALL Acute Lymphoblastic Leukaemia",
    code: 2,
  };
  static CML: EnumValue = {
    name: "CML Chronic Myelogenous Leukaemia",
    code: 3,
  };
  static OL: EnumValue = { name: "OL Other Leukaemia", code: 4 };
  static HL: EnumValue = { name: "HL Hodgkin's Lymphoma", code: 5 };
  static MDS: EnumValue = { name: "MDS Myelodysplastic Disorders", code: 6 };
  static NHL: EnumValue = { name: "NHL Non-Hodgkin's Lymphoma", code: 7 };
  static PCD: EnumValue = { name: "PCD Plasma Cell Disorder", code: 8 };
  static HIS: EnumValue = { name: "HIS Histiocytic Disorders", code: 9 };
  static OM: EnumValue = { name: "OM Other Malignancy", code: 10 };
  static IEA: EnumValue = {
    name: "IEA Inherited Erythrocyte Abnormality",
    code: 11,
  };
  static IIS: EnumValue = {
    name: "IIS Inherited Immune System Disorder",
    code: 12,
  };
  static IMD: EnumValue = {
    name: "IMD Inherited Metabolic Disorder",
    code: 13,
  };
  static IPA: EnumValue = {
    name: "IPA Inherited Platelet Abnormality",
    code: 14,
  };
  static SAA: EnumValue = { name: "SAA Severe Aplastic Anaemia", code: 15 };
  static OND: EnumValue = { name: "OND Other Non-malignant Disease", code: 16 };
}
